screen-on-my-way {
  display: block;

  seven-segment-panel {
    display: flex;
    align-items: center;
  }

  #timerbox {
    display: inline-flex;
    width: 225px;
    height: 90px;
    background-color: white;
    justify-content: center;
  }
}

screen-onmyway-center {
  display: block;
  text-align: center;
}
