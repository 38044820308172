/**
 * These colors are mirrored in constants.ts and need to be manually kept in sync if the style guide changes
 */

$blue_dark: #02253c;
$blue_med: #26436e;
$blue_light: #92a1b7;
$blue_ultralight: #c8d0db;
$blue_bright: #077ad3;
$grey_light: #eaeaea;
$grey_medium: #939393;
$grey_dark: #393939;
$orange: #fa7000;
$red: #c20000;
$green: #448e1b;
$white: #ffffff;
$black: #000000;
$purple: #5f2293;

@mixin openSansItalic {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
}

@mixin openSansRegular {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
}

@mixin openSansSemiBold {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin openSansSemiBoldItalic {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.std_page_title {
  @include openSansItalic();
  font-size: 24px;
  color: $blue_med;
}

.std_body_content {
  @include openSansRegular();
  font-size: 14px;
  color: $blue_med;
}

.std_body_content_title {
  @include openSansSemiBoldItalic();
  font-size: 16px;
  color: $blue_med;
}

.std_body_italic {
  @include openSansItalic();
  font-size: 14px;
  color: $blue_med;
}

.std_link_text {
  @include openSansSemiBoldItalic();
  font-size: 14px;
  color: $blue_bright;
}

.std_link_text_hover {
  @include openSansSemiBoldItalic();
  font-size: 14px;
  color: $blue_dark;
}

.side_nav_active {
  @include openSansItalic();
  font-size: 20px;
  color: $blue_med;
}

.side_nav_inactive {
  @include openSansItalic();
  font-size: 20px;
  color: $grey_dark;
}

.side_nav_hover {
  @include openSansItalic();
  font-size: 20px;
  color: $blue_bright;
}

.std_control_label1 {
  @include openSansItalic();
  font-size: 14px;
  color: $blue_med;
}

.std_control_label2 {
  @include openSansSemiBoldItalic();
  font-size: 14px;
  color: $grey_dark;
}

.std_control_hint_text {
  @include openSansItalic();
  font-size: 12px;
  color: $grey_dark;
}

.std_control_placeholder_text {
  @include openSansItalic();
  font-size: 14px;
  color: $grey_medium;
}

.std_control_text {
  @include openSansRegular();
  font-size: 16px;
  color: $grey_dark;
}

.std_control_error_text {
  @include openSansRegular();
  font-size: 16px;
  color: $red;
}

.std_error_hint_text {
  @include openSansItalic();
  font-size: 14px;
  color: $red;
}

.std_big_text {
  @include openSansItalic();
  font-size: 23px;
  color: $blue_bright;
}

a,
a:visited {
  @extend .std_link_text;
  &:hover {
    @extend .std_link_text_hover;
  }
}

@mixin formFieldoverrides {
  .MuiTextField-root {
    input {
      padding: $standardPaddingOrange;
    }

    input::placeholder {
      @extend .std_control_placeholder_text;
    }
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: $grey_medium;
    }
  }
}

$standardPaddingYellow: 4px;
$standardPaddingOrange: 8px;
$standardPaddingPink: 12px;
$standardPaddingGreen: 16px;
$standardPaddingPurple: 24px;
$standardPaddingBlue: 32px;
$standardPaddingRed: 64px;

$standardBorderRadiusYellow: $standardPaddingYellow;
$standardBorderRadiusOrange: $standardPaddingOrange;
$standardBorderRadiusPink: $standardPaddingPink;

// These are the same breakpoints as being used by Material UI (AFAIK)
// if there is any need to change these please ensure this and also Material UI stay in sync

$breakpoint_sm: 600px;
$breakpoint_md: 960px;
$breakpoint_lg: 1280px;
$breakpoint_xl: 1920px;

@mixin xs {
  @media (max-width: ($breakpoint_sm - 1px)) {
    @content;
  }
}

@mixin sm {
  @media (max-width: ($breakpoint_md - 1px)) and (min-width: $breakpoint_sm) {
    @content;
  }
}

@mixin sm-and-smaller {
  @media (max-width: ($breakpoint_md - 1px)) {
    @content;
  }
}

@mixin sm-and-larger {
  @media (min-width: $breakpoint_sm) {
    @content;
  }
}

@mixin md {
  @media (max-width: ($breakpoint_lg - 1px)) and (min-width: $breakpoint_md) {
    @content;
  }
}

@mixin md-and-larger {
  @media (min-width: $breakpoint_md) {
    @content;
  }
}

@mixin md-and-smaller {
  @media (max-width: ($breakpoint_lg - 1px)) {
    @content;
  }
}

@mixin lg {
  @media (max-width: ($breakpoint_xl - 1px)) and (min-width: $breakpoint_lg) {
    @content;
  }
}

@mixin lg-and-smaller {
  @media (max-width: ($breakpoint_xl - 1px)) {
    @content;
  }
}

@mixin lg-and-larger {
  @media (min-width: $breakpoint_lg) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $breakpoint_xl) {
    @content;
  }
}

@mixin desktop-view {
  @include md-and-larger {
    @content;
  }
}

@mixin mobile-view {
  @include sm-and-smaller {
    @content;
  }
}

$standardOutsetShadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
  rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

$verticalShadow: rgba(0, 0, 0, 0.2) 0px 2px 0px -1px,
  rgba(0, 0, 0, 0.14) 0px 1px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 0px 0px;

@mixin zIndex($base, $nudge) {
  @if ($base < 10) {
    @error "zIndex base must be a number in the set {10,20,30,40,50}";
  }
  @if ($base > 60) {
    @error "zIndex base must be a number in the set {10,20,30,40,50, 60}";
  }
  $remainderBase: $base % 10;
  @if ($remainderBase != 0) {
    @error "zIndex base must be a number in the set {10,20,30,40,50,60}";
  }
  @if ($nudge < 0) {
    @error "zIndex: nudge must be a number in the set {0, 1, 2, 3, 4, 5, 6, 7, 8, 9}";
  }
  @if ($nudge > 9) {
    @error "zIndex: nudge must be a number in the set {0, 1, 2, 3, 4, 5, 6, 7, 8, 9}";
  }
  $remainderNudge: $nudge % 1;
  @if ($remainderNudge != 0) {
    @error "zIndex: nudge must be a number in the set {0, 1, 2, 3, 4, 5, 6, 7, 8, 9}";
  }
  z-index: $base + $nudge;
}

@mixin zIndexBackground($nudge: 0) {
  @include zIndex(10, $nduge);
}
@mixin zIndexPanel($nudge: 0) {
  @include zIndex(20, $nudge);
}
@mixin zIndexContent($nudge: 0) {
  @include zIndex(30, $nudge);
}
@mixin zIndexModalBackdrop($nudge: 0) {
  @include zIndex(40, $nudge);
}
@mixin zIndexModal($nudge: 0) {
  @include zIndex(50, $nudge);
}
@mixin zIndexWatermark($nudge: 0) {
  @include zIndex(60, $nudge);
  pointer-events: none;
}
