@import "../../../constants.scss";

$indicatorHeight: 18px;
$backgroundExtension: 10px;

chat-typing-indicator {
  display: flex;
  align-items: flex-end;
  @extend .std_control_hint_text;
  padding-left: $standardPaddingYellow;
  padding-top: 2px; // custom override by Steve
  min-height: $indicatorHeight + $backgroundExtension;
  left: 1px;
  right: 1px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  );
}
