input-camera {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: black;

  video {
    flex-basis: 0;
    flex-grow: 1;
  }

  .MuiCircularProgress-root {
    margin: auto;
  }
}
