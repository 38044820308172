@import "../../../constants.scss";

chat-log {
  display: block;
  padding-bottom: 30px;

  overflow-y: scroll;

  top-spacer {
    display: block;
    height: calc(100% - 50px);
  }

  scroll-magnet {
    display: block;
    margin-top: 30px;
  }
}
