@import "../../../constants.scss";
@import "../ChatComposer/ChatComposer.scss";

$chatWindowHeight: 500px;

chat-area {
  chat-title {
    @extend .std_body_italic;
  }

  chat-display {
    display: grid;
    grid-template-rows: auto $composerHeight;
    grid-template-columns: auto;
    background: $white;

    height: $chatWindowHeight;
    min-height: $chatWindowHeight;
    max-height: $chatWindowHeight;

    border: 1px solid $grey_medium;
    border-radius: $standardBorderRadiusPink;
    border-top-right-radius: 0; //Otherwise it looks bad when the scrollbar appears

    position: relative;

    chat-log {
      grid-row: 1;
      grid-column: 1;
    }
    top-fade {
      position: absolute;
      top: 0;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 75%,
        rgba(255, 255, 255, 1) 100%
      );
      border-top-left-radius: $standardBorderRadiusPink - 2px;
      display: block;
      left: 2px; //custom override steve
      right: 2px; //custom override steve
      height: 60px;
    }

    chat-composer {
      grid-row: 2;
      grid-column: 1;
    }

    chat-typing-indicator {
      position: absolute;
      bottom: $composerHeight;
    }
  }
}
