@import "../../Shared/constants.scss";

screen-health-card-needed {
  display: block;
  frosted-glass-panel image-box {
    width: 80vmin;
    height: 80vmin;
    max-width: 300px;
    max-height: 300px;
    border-radius: $standardBorderRadiusPink;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25),
      inset 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.6);

    @include xs {
      width: 60vmin;
      height: 60vmin;
    }
  }
  screen-health-card-needed-skip {
    display: block;
    text-align: center;
    margin-top: $standardPaddingPink;

    a {
      text-decoration: underline;
    }
  }
}
