@import "../../../constants.scss";

chat-error {
  display: block;
  border: 1px solid $grey_medium;
  border-radius: $standardBorderRadiusPink;
  padding: $standardPaddingOrange;
  background-color: $white;
  @extend .std_control_label2;
  color: $blue_med;
}
