@import "../../../constants.scss";

chat-system-message {
  display: block;

  margin: $standardPaddingOrange;
  @extend .std_control_hint_text;

  margin-top: 12px;
  text-align: center;
  font-size: 14px;
  max-width: 100%;
  overflow-wrap: anywhere;

  color: $blue_med;
}
