@import "../../constants.scss";

$background: rgba(128, 128, 128, 0.1);
$foreground: $blue_med;

seven-segment-display {
  display: inline-flex;
  height: 100%;
  //transform: skewX(-5deg); //The updated paths from Steve already include a skew baked in
  min-width: 12px;
  min-height: 20px;

  width: 40px;
  > svg {
    width: 100%;
  }

  // Src: https://en.wikipedia.org/wiki/File:7-segment_abcdefg.svg
  // License: Public Domain
  //
  #abcdefg {
    fill-rule: evenodd;
    stroke: $background;
    stroke-width: 0.25;
    stroke-opacity: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
  }

  #a,
  #b,
  #c,
  #d,
  #e,
  #f,
  #g {
    fill: $background;
    transition: all 0.125s ease;
  }

  &.a #a,
  &.b #b,
  &.c #c,
  &.d #d,
  &.e #e,
  &.f #f,
  &.g #g {
    fill: $foreground;
  }
}
