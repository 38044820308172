lcr-columns {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;

  lcr-column {
    &:nth-child(1) {
      grid-column: 1;
      display: grid;
      grid-template-columns: auto 1fr;
    }
    &:nth-child(2) {
      grid-column: 2;
    }
    &:nth-child(3) {
      grid-column: 3;
      grid-template-columns: 1fr auto;
      display: grid;
      > lcr-column-content {
        grid-column: 2;
      }
    }

    lcr-column-content {
      display: block;
    }
  }
}
