screen-welcome {
  display: block;

  app-title {
    display: block;
    color: black;
    font-weight: 600;
  }

  $digitSize: 64px;
  seven-segment-display {
    height: $digitSize;
    width: $digitSize / 2;
  }

}
