@import "../../Shared/constants.scss";

screen-confirm-leave-queue {
  display: block;

  footer-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 50%;

      &:nth-child(1) {
        margin-bottom: $standardPaddingGreen;
      }
    }
  }
}
