@import "../../constants.scss";

visitor-flag-icon-list {
  display: block;

  visitor-flag-icon {
    margin-left: $standardPaddingYellow;
  }

  &.small {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 50% 50%;
    height: fit-content;
    // horizontal flip to keep icons positioning
    transform: scale(-1, 1);

    visitor-flag-icon {
      margin: 0;
    }
  }
}
