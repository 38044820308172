visitor-flag-icon {
  display: inline-block;

  image-box {
    width: 24px;
    height: 24px;
  }

  &.small {
    image-box {
      display: block;
      width: 10px;
      height: 10px;
      margin: 1px;
    }
  }
}
