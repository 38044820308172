@import "../../../Shared/constants.scss";

$paddingAmount: 0px;

full-screen-panel-layout {
  display: block;
  width: 100vw;
  min-height: 100vh; // fallback for browsers that do not support custom properties
  // eslint-disable-next-line no-dupe-keys
  min-height: calc(var(--vh, 1vh) * 100);
  padding: $paddingAmount;
  height: 100%;
}
