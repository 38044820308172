macadamian-vwr {
  display: block;
  min-height: 100vh; // fallback for browsers that do not support custom properties
  // eslint-disable-next-line no-dupe-keys
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
