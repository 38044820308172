inq-number-picker {
  display: inline-block;
  .MuiInputBase-input {
    padding-left: 4px;
  }

  inq-number-picker-arrows {
    display: flex;
    flex-direction: column;
    height: 30px;
    padding-right: 4px;

    .arrowButton {
      padding: 0;
      height: 15px;
      width: 15px;
    }
  }
}
