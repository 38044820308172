@import "../../Shared/constants.scss";

screen-summary {
  display: block;

  frosted-glass-panel {
    image-box {
      width: 40px;
      height: 40px;
    }
  }

  summary-fields {
    display: block;
    position: relative;

    edit-button {
      display: block;
      text-align: center;
    }
    .healthDocument {
      field-label {
        align-self: center;
      }
    }
  }

  #retakePhoto {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
