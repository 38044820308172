@import "../../constants.scss";

icon-button {
  padding: 0px;
  height: $standardPaddingBlue;
  width: $standardPaddingBlue;
  border: 1px solid;
  border-radius: $standardBorderRadiusYellow;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;

  image-box {
    height: $standardPaddingPurple;
    width: $standardPaddingPurple;
  }

  &.active {
    opacity: 1;
    &:hover:not(.no-click) {
      opacity: 0.8;
    }
  }
  &.inactive {
    opacity: 0.4;
    &:hover:not(.no-click) {
      opacity: 0.75;
    }
  }

  &.no-click {
    border-color: transparent;
    cursor: not-allowed;
  }
}
