@import "../../constants.scss";

build-number {
  display: block;
  opacity: 0;
  color: $black;
  @include openSansRegular();
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: $standardPaddingYellow;
  padding-top: 0;
  @include zIndexWatermark();
  background-color: rgba(255, 255, 255, 0.125);
  border: 0px solid transparent;
  border-top-right-radius: $standardBorderRadiusYellow;
}
