screen-closing {
  display: block;

  app-title {
    display: block;
    color: black;
    font-weight: 600;
  }

  screen-closing-center {
    display: block;
    text-align: center;
  }
}
