@import "../../Shared/constants.scss";

screen-its-your-turn#screen {
  display: block;

  frosted-glass-panel {
    background-color: rgba(38, 67, 110, 0.7);
  }

  screen-title {
    color: $white;
  }

  text-description {
    color: $white;
  }

  text-hint {
    color: $white;
  }

  button {
    background-color: $white;
    color: $blue_med;
  }

  screen-its-your-turn-center {
    display: block;
    text-align: center;

    text-description {
      margin-bottom: $standardPaddingBlue;
    }
  }
}
