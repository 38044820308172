@import "../../constants.scss";

inq-color-picker {
  display: flex;
  flex-direction: row;

  inq-color-text {
    width: $standardPaddingRed;
  }
  inq-color-select {
    position: relative;
    display: flex;
    flex-direction: column;
    inq-color-box {
      display: inline-block;
      width: $standardPaddingPurple;
      height: $standardPaddingPurple;
      outline: 1px solid gray;
      outline-offset: $standardPaddingYellow;
      border-radius: $standardPaddingYellow;
      margin-left: $standardPaddingYellow;
    }
    inq-color-popover {
      position: absolute;
      top: $standardPaddingBlue;
      @include zIndexModal();
    }
    inq-color-cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }
}
