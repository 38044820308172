@import "../../../Shared/constants.scss";

screen-question-yes-no {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .yesNo {
    margin-right: $standardPaddingPink;
    margin-left: $standardPaddingPink;
  }
}

screen-question-details {
  text-description,
  inq-checkbox {
    margin-bottom: 0;
  }
}
