@import "../../../Shared/constants.scss";

$logoHeight: 48px;
$inQLogoSize: 32px;
$headerHeight: 72px;
$footerHeight: 36px;
$footerOffset: $standardPaddingPurple;
$maxPanelWidth: 600px;

single-panel-layout {
  display: flex;
  width: 100vw;
  min-height: 100vh; // fallback for browsers that do not support custom properties
  // eslint-disable-next-line no-dupe-keys
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 0,
      rgba(255, 255, 255, 0.9) ($logoHeight + ($standardPaddingPink * 2)),
      rgba(255, 255, 255, 0) 200px + ($logoHeight + ($standardPaddingPink * 2))
    ),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0,
      rgba(255, 255, 255, 0.7) ($logoHeight + ($standardPaddingPink * 2)),
      rgba(255, 255, 255, 0) 200px + ($logoHeight + ($standardPaddingPink * 2))
    );

  > panel-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-grow: 0;
    margin: $standardPaddingPink;
    height: $headerHeight;

    #logo {
      width: calc(100% - 110px);
      height: $logoHeight;
      background-position: left;
    }

    #inQLogo {
      width: $inQLogoSize;
      height: $inQLogoSize;
    }

    > user-preferences {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  > frosted-glass-panel {
    // min is both an scss function and also a css function but they have different signatures. To bypass the signature validation we do
    // interpolation over the "min" so that the scss compiler doesn't recognize it, but the browser still will.
    max-width: m#{i}n($maxPanelWidth, 100vw - #{$standardPaddingGreen * 2});
    align-self: center;

    margin-left: $standardPaddingGreen;
    margin-right: $standardPaddingGreen;
    margin-bottom: $standardPaddingBlue;
    flex-grow: 1;
    padding: $standardPaddingGreen;
    padding-bottom: ($footerOffset) + $footerHeight + $standardPaddingPurple; // TODO, add padding to compensate for footer height (which is variable)
    height: 100%;
    min-height: calc(
      100vh - 140px
    ); // work around for chrome child not filling flex-positioned parent
    // eslint-disable-next-line no-dupe-keys
    min-height: calc(var(--vh, 1vh) * 100 - 140px);

    > panel-footer {
      display: block;
      position: absolute;
      bottom: $footerOffset;
      width: calc(100% - #{$standardPaddingGreen * 2});
      text-align: center;
    }

    > panel-title {
      display: block;
      padding-bottom: $standardPaddingPurple;
    }
  }
}
