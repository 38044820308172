@import "../../constants.scss";

inq-toggle-switch {
  display: inline-block;
  margin-bottom: $standardPaddingYellow;
  > input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  > label {
    display: flex;
  }

  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    label,
    input {
      pointer-events: none;
    }
  }

  svg {
    display: inline-block;

    .knob,
    .middleKnob {
      transition: all 0.125s ease;
    }

    &.unchecked .knob {
      transform: translate(0, 1px);

      .middleKnob {
        fill: #e6e6e8;
      }
    }
    &.checked .knob {
      transform: translate(20px, 1px);

      .middleKnob {
        fill: #27446f;
      }
    }
  }

  inq-toggle-switch-visual {
    display: flex;
    align-items: center;
  }

  inq-toggle-switch-label {
    @extend .std_control_text;
    display: inline-block;
    margin-left: $standardPaddingOrange;
  }
}
