@import "../../../constants.scss";

$bigSize: 40px;
$smallSize: 20px;

chat-avatar {
  border-radius: $bigSize;
  background-color: $blue_med;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: default;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.big {
    width: $bigSize;
    height: $bigSize;
    min-width: $bigSize;
    min-height: $bigSize;
  }

  &.small {
    width: $smallSize;
    height: $smallSize;
    min-width: $smallSize;
    min-height: $smallSize;
  }

  user-initials {
    display: none;
    text-transform: capitalize;
    color: $white;
  }
}
