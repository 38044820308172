@import "../../constants.scss";

graph-viewer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
  padding: 6px;
  margin-bottom: $standardPaddingPurple;
  background-color: $white;

  graph-viewer-start {
    display: block;
    text-align: left;
    margin-right: $standardPaddingOrange;
  }
}
