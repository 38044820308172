@import "../../../Shared/constants.scss";

$buttonSize: 20px;

language-selector {
  image-box {
    width: $buttonSize;
    height: $buttonSize;
    margin-right: $standardPaddingYellow;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    min-width: 0px;
    padding: 0;
    vertical-align: top;
  }
}
