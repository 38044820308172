@import "../../Shared/constants.scss";

screen-waiting-in-queue {
  min-height: 100vh; // fallback for browsers that do not support custom properties
  // eslint-disable-next-line no-dupe-keys
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;

  text-hint {
    text-align: center;
  }

  inq-checkbox {
    label {
      @extend .std_body_content;
    }
  }

  $digitSize: 64px;
  seven-segment-display {
    height: $digitSize;
    width: $digitSize / 2;
  }
}
