@import "./Shared/constants.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;

  background-image: url("../public/inQ_BackgroundImage_blurred.png");
  @supports (backdrop-filter: blur(10px)) {
    background-image: url("../public/inQ_BackgroundImage.jpg");
    @media (min-width: 188px) {
      background-image: url("../public/inQ_BackgroundImage_hiRez_40p.jpg");
    }
  }
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  &.unsupported #root {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.MuiButton-root,
button.MuiButton-root {
  text-transform: none;
}

.MuiButton-containedPrimary span {
  color: $white;
}
