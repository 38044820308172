@import "../../constants.scss";

$content-height: 16px;

toggle-button {
  border: 1px solid $blue_med;
  padding: $standardPaddingYellow;
  border-radius: $standardBorderRadiusOrange;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: $content-height;

  > * {
    margin-right: $standardPaddingYellow;
  }

  &.inactive {
    background-color: rgba(255, 255, 255, 0.25);
    color: $blue_med;
  }
  &.active {
    background-color: rgba(255, 255, 255, 1);
    color: $blue_med;
  }
  &.disabled {
    opacity: 0.75;
    color: white;
    cursor: not-allowed;
  }

  image-box {
    display: block;
    max-width: $content-height;
    max-height: $content-height;
  }
}
