@import "../../constants.scss";

queue-viewer {
  display: block;
  text-align: center;
  seven-segment-panel {
    margin-bottom: $standardPaddingPink;
  }
}

queue-viewer-start {
  display: block;
}

queue-viewer-end {
  display: block;
}
