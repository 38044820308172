@import "../../Shared/constants.scss";

$headerSize: 260px;
$listSize: calc(100vh - #{$headerSize});

screen-whats-nearby {
  display: block;

  panel-contents {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: $listSize; // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    min-height: calc(var(--vh, 1vh) * 100 - #{$headerSize});
  }

  single-panel-layout frosted-glass-panel {
    padding-bottom: 0;
  }

  app-title {
    display: inline;
    color: black;
    font-weight: 600;
  }

  $listNudgeDown: 5px;
  ul {
    flex-grow: 1;
    display: block;
    background-color: white;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-overflow: ellipsis;
    margin: 0px;
    padding: $standardPaddingOrange;
    border-radius: $standardBorderRadiusOrange;
    width: 100%;
    margin-bottom: $standardPaddingOrange;

    li {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      position: relative;
      margin-bottom: $standardPaddingGreen;
      cursor: pointer;

      > span {
        @include openSansSemiBold();
        font-size: 16px;
        color: $blue_med;
        position: relative;
      }
    }
  }

  text-hint {
    text-align: center;
    margin: 0;
  }

  busy-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: $listSize; // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    height: calc(var(--vh, 1vh) * 100 - #{$headerSize});
    text-align: center;
  }

  .refreshLocation {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  $digitSize: 20px;
  seven-segment-panel {
    display: inline-flex;
    margin-bottom: 0;
    position: relative;
    top: $listNudgeDown;

    seven-segment-display {
      height: $digitSize;
      width: $digitSize / 2;
    }
  }

  queue-viewer {
    position: relative;
    display: flex;
    align-items: baseline;
    > span {
      position: relative;
      margin-left: $standardPaddingYellow;
      @extend .std_body_content;
      font-size: 12px;
    }
  }

  in-screen-nav {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: $standardPaddingOrange;
    text-description {
      margin-bottom: $standardPaddingOrange;
    }

    sort-selector.sort-selector {
      //This classname has been appended as a workaround for overriding material-ui styles
      flex-grow: 1;
      text-align: right;

      button {
        padding: $standardPaddingYellow;
        margin: 0;
        margin-left: $standardPaddingYellow;
        border-width: 1px;
        box-sizing: border-box;
        min-width: 0;
      }
    }
  }
}
