@import "../../constants.scss";

$iconSize: 18px;
$iconNudge: 4px;

inq-checkbox {
  display: block;
  margin-bottom: $standardPaddingPink;

  svg {
    fill: $blue_med;
    width: $iconSize;
    height: $iconSize;
    margin-right: $standardPaddingOrange;
    position: relative;
    top: $iconNudge;
  }

  &.disabled svg {
    fill: $blue_light;
  }

  input {
    pointer-events: none;
    opacity: 0;
    position: absolute;
  }
  label {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    @extend .std_control_text;
  }

  inq-label {
    flex-basis: 0;
    flex-grow: 1;
  }
}
