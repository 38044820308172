@import "../../Shared/constants.scss";

screen-please-wait {
  display: block;

  frosted-glass-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      margin-top: -20px;
    }
  }
}
