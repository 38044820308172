@import "../../Shared/constants.scss";

$controlHeight: 100px;
$maxPreviewSize: calc(100vh - #{$controlHeight});
$borderRadius: $standardPaddingBlue;
$maxPanelWidth: 600px;

screen-health-card-capture {
  display: block;
  background-color: black;
  color: white;

  image-box,
  .placeholder {
    width: 40px;
    height: 40px;
  }

  .bigIcon {
    width: 60px;
    height: 60px;
  }

  full-screen-panel-layout {
    display: flex;
    flex-direction: column;
  }

  input-camera {
    flex-grow: 1;
  }

  camera-controls-bottom {
    display: flex;
    flex-basis: $controlHeight;
    flex-grow: 0;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: $grey_light;
    width: 100%;
    align-self: center;
  }
  camera-controls-top {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  camera-preview {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    max-height: $maxPreviewSize;
    overflow: hidden;

    #previewImage {
      flex-grow: 1;
      width: auto;
      height: auto;
    }

    video {
      max-height: $maxPreviewSize;
      max-width: 100%;
    }
  }
}
