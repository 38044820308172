@import "../../../Shared/constants.scss";

screen-on-my-way {
  display: block;

  countdown-timer {
    display: block;
    text-align: center;
    margin-bottom: $standardPaddingBlue;
  }
}
