@import "../../constants.scss";

visitor-flag-button {
  margin-right: $standardPaddingPink;
  display: flex;

  visitor-flag-icon {
    height: $standardPaddingBlue;
    width: $standardPaddingBlue;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  icon-button {
    &.high-priority {
      border-color: $purple;
    }
    &.manual-registration {
      border-color: $blue_bright;
    }
    &.high-risk {
      border-color: $red;
    }
  }
}
