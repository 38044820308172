@import "../../../constants.scss";

$composerHeight: 60px;
$iconSize: 24px;

chat-composer {
  $borderNudge: 0.5px;

  height: $composerHeight;
  min-height: $composerHeight;
  max-height: $composerHeight;
  display: flex;
  align-items: center;
  justify-items: center;

  background-color: $grey_light;
  border-bottom-left-radius: $standardBorderRadiusPink - $borderNudge;
  border-bottom-right-radius: $standardBorderRadiusPink - $borderNudge;
  padding: $standardPaddingYellow;

  composer-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    background-color: $white;
    border-radius: $standardBorderRadiusPink;
    padding: $standardPaddingYellow;
    box-shadow: inset 0px 2px 4px 0px rgba(57, 57, 57, 0.5);

    input {
      flex-grow: 1;
      border: 0px solid transparent;
      outline-width: 0;
      border-radius: $standardPaddingOrange;
      padding-left: $standardPaddingPink;

      &:active,
      &:focus {
        border: 0px solid transparent;
        outline-width: 0;
      }

      &::placeholder {
        @extend .std_control_hint_text;
      }
    }

    image-box {
      width: $iconSize;
      height: $iconSize;
    }
  }
}
